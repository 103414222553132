import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useScheduleRemoveViewModel from './ScheduleRemove.viewModel';

export default function ScheduleRemoveView() {
  const {
    translator: { translate },
    logic: {
      schedulesWithStatusDetail,
      showDoneBtn,
      mutationsIsLoading,
      onRemove,
      onCancel,
      onDone,
    },
    table,
  } = useScheduleRemoveViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <BulkActionLayout
      actionLabel={translate('to start the removal process.')}
      descriptionLabel={translate(
        'Make sure the list below is the schedule list that you want to remove. Driver and vehicle will be available at the removed schedule dates.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Remove')}
      doneLabel={translate('Done')}
      table={table}
      entityLength={schedulesWithStatusDetail.length}
      showDoneBtn={showDoneBtn}
      isLoading={mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Schedules that you want to remove",
          )}
          descriptionLabel={translate(
            'Try to select some Schedules from Schedule page',
          )}
          actionLabel={translate('Go back to Schedule page')}
          onClickAction={onDone}
        />
      }
      onCancel={onCancel}
      onSubmit={onRemove}
      onDone={onDone}
    />
  );
}
