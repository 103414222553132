import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mobileBottomBarVisiblePath } from '../../constant/Common.constant';
import { homeRoute } from '../../view/Home/Home.route';
import { profileRoute } from '../../view/Profile/Profile.route';
import useScreenDetection from '../useScreenDetection/useScreenDetection.hook';
import useTranslator from '../useTranslator.hook';

export default function useMobileTopBar() {
  const { isMobile } = useScreenDetection();
  const location = useLocation();
  const translator = useTranslator();
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState(true);
  const isTopBarVisible =
    isMobile && mobileBottomBarVisiblePath.includes(location.pathname);

  const infoText = useMemo(
    () =>
      translator.translate(
        'To access all available features, please use the desktop version.',
      ),
    [translator],
  );

  const handleClickProfile = () => {
    navigate(profileRoute.path);
  };

  const handleClickHome = () => {
    navigate(homeRoute.path);
  };

  const handleToggleInfo = () => {
    setShowInfo((v) => !v);
  };

  return {
    isTopBarVisible,
    handleClickProfile,
    handleClickHome,
    handleToggleInfo,
    showInfo,
    infoText,
  };
}
