import addMonths from 'date-fns/addMonths';
import differenceInDays from 'date-fns/differenceInDays';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import setMonthFns from 'date-fns/setMonth';
import setYearFns from 'date-fns/setYear';
import startOfDay from 'date-fns/startOfDay';
import subMonths from 'date-fns/subMonths';
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DayPicker, {
  BeforeAfterModifier,
  Modifier,
  WeekdayElementProps,
} from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';
import tw, { css, styled } from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { snackbarAction } from '../../../store/snackbar.store';
import { getMonthList } from '../../../util/date.util';
import {
  Button,
  Chip,
  Divider as BaseDivider,
  Icon,
  IconButton,
  Text,
} from '../../atom';
import { RangeYear } from '../DatePicker/DatePicker.molecule';
import { SnackbarTheme } from '../Snackbar/Snackbar.molecule';

// #region INTERFACES
export type ChoosenPicker = 'day' | 'month' | 'year';
export type CurrentSelection = 'from' | 'to';
export type DefinedRangeOption = {
  label: string;
  startRange: Date;
  endRange: Date;
};
export type DefaultDateRangeValue = {
  startDate: Date;
  endDate: Date;
};

type WrapperProps = {
  selection: CurrentSelection;
  startDate: Date | undefined;
  endDate: Date | undefined;
  onClickCancel: () => void;
  onClickApply: (
    _startDate: Date | undefined,
    _endDate: Date | undefined,
  ) => void;

  disabledDays?: Modifier | Modifier[];
  rangeYear?: RangeYear;
  maxDateRangeInDays?: number;
  definedRangeOptions?: DefinedRangeOption[];
  notes?: string;
  disableDefinedRange?: boolean;
  defaultDateRange?: DefaultDateRangeValue;
  allowUndefinedDateRange?: boolean;
};

type DayPickerProps = {
  isMobile: boolean;
  monthAndYear: Date;
  currentSelection: CurrentSelection;
  startDate: Date | undefined;
  endDate: Date | undefined;
  onClickOpenMonth: () => void;
  onDecrementMonth: () => void;
  onIncrementMonth: () => void;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;

  disabledDays?: Modifier | Modifier[];
  disableDefinedRange?: boolean;
};

type MonthPickerProps = {
  isMobile: boolean;
  monthAndYear: Date;
  onChangeMonth: (monthIdx: number) => void;
  onClickOpenYear: () => void;

  disableDefinedRange?: boolean;
};

type YearPickerProps = {
  isMobile: boolean;
  monthAndYear: Date;
  onChangeYear: (_year: number) => void;

  rangeYear?: RangeYear;
  disableDefinedRange?: boolean;
};
// #endregion

// #region STYLED COMPONENTS
const RootContainer = styled.div(
  (props: { disableDefinedRange: boolean; isMobile: boolean }) => [
    tw`bg-white rounded-lg overflow-hidden shadow-calendar width[500px] text-black relative`,
    props.disableDefinedRange && tw`width[375px]`,
    props.isMobile && tw`w-full min-height[570px] flex flex-col`,
  ],
);
const HeaderContainer = styled.section(
  (props: { withNotes: boolean; isMobile: boolean }) => [
    tw`bg-orange h-16 flex items-center pl-5`,
    props.withNotes && tw`flex-col justify-center items-start space-y-1 h-20`,
    props.isMobile && tw`bg-white overflow-x-auto p-5 h-auto`,
  ],
);
const HeaderText = tw(Text.HeadingFour)`text-white`;
const WrapperBodyContainer = styled.section(
  (props: { disableDefinedRange: boolean; isMobile: boolean }) => [
    tw`flex w-full border-b border-b-grey-five`,
    props.disableDefinedRange && tw`justify-center`,
    props.isMobile && tw`flex-grow border-0`,
  ],
);
const DefinedRangeContainer = tw.div`flex flex-col py-2.5 width[35%] border-r border-r-grey-five`;
const DefinedRangeButton = styled.button((props: { isSelected?: boolean }) => [
  tw`py-2.5 pl-5 pr-5 text-sm text-left font-semibold rounded-md hover:(bg-orange-hover text-orange)`,
  props.isSelected && tw`text-orange`,
]);
const WrapperFooterContainer = tw.section`flex items-center px-5 py-2.5 justify-between bg-white`;
const Caption = tw.div`w-full flex items-center justify-center mb-5`;
const IconButtonContainer = tw(
  IconButton,
)`w-8 h-8 p-1 justify-center text-orange transform rotate-90`;

// DAY
const DaysContainer = styled.div`
  ${tw`p-5 pb-2.5 width[65%]`},

  .DayPicker {
    ${tw`font-sans font-size[14px] `}
  }

  > .DayPicker > .DayPicker-wrapper > .DayPicker-Months > .DayPicker-Month > .DayPicker-Caption {
    ${tw`hidden`}
  }

  & .DayPicker-WeekdaysRow,
  .DayPicker-Week {
    ${tw`flex justify-between my-3 overflow-hidden`}
  }

  & .DayPicker-Weekday,
  .DayPicker-Day {
    ${tw`h-9 w-10 flex items-center justify-center relative`}
  }

  & .DayPicker-Day div {
    ${tw`absolute h-9 w-9 flex items-center justify-center z-index[1] duration-200`}
  }

  &
    .DayPicker-Day--outside:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--selected),
  .DayPicker-Day--disabled {
    ${tw`opacity-40 cursor-default`}
  }

  & .DayPicker-Day--start div {
    ${tw`rounded-full bg-orange text-white`}
  }

  & .DayPicker-Day--end div {
    ${tw`rounded-full bg-orange text-white`}
  }

  & .DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected::before {
    ${tw`bg-transparent`}
  }

  & .DayPicker-Day:not(.DayPicker-Day--start):not(.DayPicker-Day--end):hover {
    ${tw`bg-orange-hover cursor-pointer rounded-full`}
  }
  & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    ${tw`cursor-pointer before:(absolute -inset-x-1/2 h-full bg-orange-hover)`}
  }
  & .DayPicker-Day--selected.DayPicker-Day--end {
    ${tw`cursor-pointer before:(absolute left-[-60%] right-[40%] h-full bg-orange-hover)`}
  }
`;
const DayTime = styled.div(() => [
  tw`relative`,

  css`
    &[data-is-today='true']:not(&[data-is-start-daterange='true']):not(&[data-is-within-daterange='true']):not(&[data-is-end-daterange='true']) {
      ${tw`before:(absolute inset-0 rounded-full border-2 border-black)`}
    }
  `,
]);

// MONTH
const TopDivider = tw(BaseDivider)`w-full mb-3.5`;
const Divider = tw(BaseDivider)`w-full my-3.5`;
const MonthsContainer = styled.div`
  ${tw`flex flex-wrap justify-between p-5 pb-2.5 width[65%]`}

  & .MonthPicker-Month {
    ${tw`h-9 w-1/3 flex items-center justify-center z-index[1] rounded-full duration-200`}
  }

  & .MonthPicker-Month:not(.MonthPicker-Month--start):not(.MonthPicker-Month--end):hover,
  .MonthPicker-Month--selected:not(.MonthPicker-Month--start):not(.MonthPicker-Month--end) {
    ${tw`bg-orange-hover text-black cursor-pointer`}
  }
`;

// YEAR
const YearsContainer = styled.div`
  ${tw`p-5 pb-2.5 width[65%]`}

  & .YearPicker-Years {
    ${tw`height[260px] overflow-y-scroll relative`}

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & .YearPicker-Years::-webkit-scrollbar {
    ${tw`hidden`}
  }

  & .YearPicker-Year {
    ${tw`h-16 w-full flex items-center justify-center border-solid border-0 border-b border-b-grey-five duration-200 hover:text-orange-two`}
  }

  & .YearPicker-Year--current {
    ${tw`font-size[32px] font-semibold text-orange`}
  }
`;
// #endregion

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// #region YEAR PICKER
export function Year({
  isMobile,
  monthAndYear,
  onChangeYear,
  rangeYear,

  disableDefinedRange = false,
}: YearPickerProps) {
  // #region VALUES
  const scrollRef = createRef<HTMLDivElement>();

  const yearOptions = useMemo(() => {
    if (rangeYear) {
      const { from, to } = rangeYear;
      return Array.from(Array(to - from + 1).keys()).map((i) => i + from);
    }

    // Default year range 25 years to the past and 25 years to the future
    return Array.from(Array(50).keys()).map(
      (i) => i + new Date().getFullYear() - 25,
    );
  }, [rangeYear]);
  // #endregion

  // #region HANDLERS
  const handleYearClick = (_year: number): void => {
    onChangeYear(_year);
  };
  // #endregion

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        behavior: 'smooth',
        top:
          (document.getElementById(
            `YearPicker-year-${monthAndYear.getFullYear()}`,
          )?.offsetTop as number) - 65,
      });
    }
  }, [scrollRef, monthAndYear]);

  return (
    <YearsContainer
      data-testid="DateRangePicker:Year:YearsContainer"
      css={[(disableDefinedRange || isMobile) && tw`w-full`]}
    >
      <Caption data-testid="DateRangePicker:Year:YearsContainer:Caption">
        <Text.HeadingFour>Year</Text.HeadingFour>
      </Caption>

      <div className="YearPicker-Years" ref={scrollRef}>
        {yearOptions.map((_year) => (
          <button
            type="button"
            key={`YearPicker-year-${_year}`}
            id={`YearPicker-year-${_year}`}
            className={`YearPicker-Year ${
              _year === monthAndYear.getFullYear()
                ? 'YearPicker-Year--current'
                : ''
            }`}
            onClick={() => handleYearClick(_year)}
          >
            {_year}
          </button>
        ))}
      </div>
    </YearsContainer>
  );
}
// #endregion

// #region MONTH PICKER
export function Month({
  isMobile,
  monthAndYear,
  onChangeMonth,
  onClickOpenYear,

  disableDefinedRange = false,
}: MonthPickerProps) {
  const currentLanguage = useSelector(
    (state: RootState) => state.setting.currentLanguage,
  );

  const listMonth = useMemo(
    () => getMonthList(currentLanguage),
    [currentLanguage],
  );

  // #region HANDLERS
  const handleMonthClick = (idx: number) => {
    onChangeMonth(idx);
  };
  // #endregion

  return (
    <MonthsContainer
      data-testid="DateRangePicker:Month:MonthsContainer"
      css={[(disableDefinedRange || isMobile) && tw`w-full`]}
    >
      <Caption data-testid="DateRangePicker:Month:MonthsContainer:Caption">
        <Text.HeadingFour>{monthAndYear.getFullYear()}</Text.HeadingFour>
        <IconButtonContainer
          onClick={(e) => {
            e.stopPropagation();
            onClickOpenYear();
          }}
        >
          <Icon.ChevronSharp height={20} width={20} strokeWidth={3} />
        </IconButtonContainer>
      </Caption>

      <TopDivider />

      {listMonth.map((_month, idx) => (
        <React.Fragment key={`MonthPicker-Month-${_month}`}>
          <button
            type="button"
            className="MonthPicker-Month"
            css={[
              monthAndYear.getMonth() <= idx &&
                monthAndYear.getMonth() >= idx &&
                tw`bg-orange text-white`,
            ]}
            onClick={(e) => {
              e.stopPropagation();
              handleMonthClick(idx); // idx starts at 0, which equals to "January"
            }}
          >
            {_month.slice(0, 3)}
          </button>

          {idx % 3 === 2 && <Divider />}
        </React.Fragment>
      ))}
    </MonthsContainer>
  );
}
// #endregion

// #region DATE PICKER
export function Day({
  isMobile,
  monthAndYear,
  currentSelection,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onClickOpenMonth,
  onDecrementMonth,
  onIncrementMonth,

  disabledDays,
  disableDefinedRange = false,
}: DayPickerProps) {
  const translator = useTranslator();

  // #region HANDLERS
  const renderWeekdayElement = useCallback(
    ({ weekday, locale }: WeekdayElementProps) => (
      <div className="DayPicker-Weekday" role="columnheader">
        {translator
          .translate(weekdays[weekday], locale as SupportedLanguage)
          .slice(0, 1)}
      </div>
    ),
    [translator],
  );

  const checkDisabledDayIsValid = (_date: Date) => {
    const typedDisabledDays = disabledDays as Partial<BeforeAfterModifier>;

    // check if "_date" is before typedDisabledDays.before
    if (
      typedDisabledDays?.before &&
      isBefore(_date, typedDisabledDays?.before)
    ) {
      return false;
    }

    // check if "_date" is after typedDisabledDays.after
    if (typedDisabledDays?.after && isAfter(_date, typedDisabledDays?.after)) {
      return false;
    }

    return true;
  };

  const handleDayClick = (dayDate: Date) => {
    if (currentSelection === 'from') {
      const startDayOfStartDate = startOfDay(dayDate);

      // check if "startDayOfStartDate" is valid based on disabledDays
      if (!checkDisabledDayIsValid(startDayOfStartDate)) return;

      setStartDate(startDayOfStartDate);
      return;
    }

    const endDayOfEndDate = startOfDay(dayDate);

    // check if "endDayOfEndDate" is valid based on disabledDays
    if (!checkDisabledDayIsValid(endDayOfEndDate)) return;

    setEndDate(endDayOfEndDate);
  };

  const getCustomDataAttrs = useCallback(
    (_day: Date) => ({
      'data-datetime': format(_day, 'yyyy-MM-dd'),
      'data-is-today': isToday(_day),
      // ----------- DATERANGE -----------
      'data-is-equal-daterange':
        !!startDate && !!endDate && isSameDay(startDate, endDate),
      'data-is-start-daterange': !!startDate && isSameDay(_day, startDate),
      'data-is-within-daterange':
        !!startDate &&
        !!endDate &&
        isWithinInterval(_day, { start: startDate, end: endDate }),
      'data-is-end-daterange': !!endDate && isSameDay(_day, endDate),
    }),
    [endDate, startDate],
  );
  // #endregion

  return (
    <DaysContainer
      data-testid="DateRangePicker:Day:DaysContainer"
      css={[(disableDefinedRange || isMobile) && tw`w-full`]}
    >
      {!isMobile && (
        <Caption data-testid="DateRangePicker:Day:DaysContainer:Caption">
          <Text.HeadingFour>
            {format(monthAndYear, 'MMMM yyyy')}
          </Text.HeadingFour>
          <IconButtonContainer
            onClick={(e) => {
              e.stopPropagation();
              onClickOpenMonth();
            }}
          >
            <Icon.ChevronSharp height={20} width={20} strokeWidth={3} />
          </IconButtonContainer>
        </Caption>
      )}

      {isMobile && (
        <>
          <Caption
            data-testid="DateRangePicker:Day:DaysContainer:Caption"
            tw="justify-between mb-1"
          >
            <IconButton
              tw="shadow-card rounded-lg p-1"
              onClick={(e) => {
                e.stopPropagation();
                onDecrementMonth();
              }}
            >
              <Icon.ChevronSharp
                height={20}
                width={20}
                strokeWidth={2}
                style={{ transform: 'rotate(180deg)' }}
              />
            </IconButton>

            <div tw="flex items-center">
              <Text.HeadingFour>
                {format(monthAndYear, 'MMMM yyyy')}
              </Text.HeadingFour>
              <IconButtonContainer
                onClick={(e) => {
                  e.stopPropagation();
                  onClickOpenMonth();
                }}
              >
                <Icon.ChevronSharp height={20} width={20} strokeWidth={3} />
              </IconButtonContainer>
            </div>

            <IconButton
              tw="shadow-card rounded-lg p-1"
              onClick={(e) => {
                e.stopPropagation();
                onIncrementMonth();
              }}
            >
              <Icon.ChevronSharp height={20} width={20} strokeWidth={2} />
            </IconButton>
          </Caption>

          <div tw="w-full flex justify-center">
            <Text.BodyFourteen>
              {!!startDate && format(startDate, 'd MMMM yyyy')}
              {!!startDate && !!endDate && ' - '}
              {!!endDate && format(endDate, 'd MMMM yyyy')}
            </Text.BodyFourteen>
          </div>
        </>
      )}

      <DayPicker
        canChangeMonth
        showOutsideDays
        locale={translator.currentLanguage}
        disabledDays={disabledDays}
        month={monthAndYear}
        renderDay={(day) => (
          <DayTime
            // NOTE: generate custom data attributes used for styling specificity
            {...getCustomDataAttrs(day)}
          >
            {day.getDate()}
          </DayTime>
        )}
        selectedDays={[startDate, { from: startDate, to: endDate }]}
        modifiers={{ start: startDate, end: endDate }}
        onDayClick={handleDayClick}
        weekdayElement={renderWeekdayElement}
      />
    </DaysContainer>
  );
}
// #endregion

// #region WRAPPER
export function Wrapper({
  selection,
  startDate,
  endDate,
  onClickCancel,
  onClickApply,
  disabledDays,
  rangeYear,
  maxDateRangeInDays,
  definedRangeOptions,
  notes,
  disableDefinedRange = false,
  defaultDateRange,
  allowUndefinedDateRange = false,
}: WrapperProps) {
  // #region MOBILE
  const { isMobile } = useScreenDetection();
  // #endregion

  // #region VALUES
  const dispatch = useDispatch();
  const translator = useTranslator();

  const [choosePicker, setChoosePicker] = useState<ChoosenPicker>('day');
  const [currentSelection, setCurrentSelection] =
    useState<CurrentSelection>(selection);
  const [from, setFrom] = useState<Date | undefined>(startDate);
  const [to, setTo] = useState<Date | undefined>(endDate);
  const [monthAndYear, setMonthAndYear] = useState<Date>(
    startDate ?? new Date(),
  );

  const dateRangeDifferenceInDays = from && to && differenceInDays(to, from);

  const checkDisabledSubmitBtn = useMemo(() => {
    // if "allowUndefinedDateRange", then enable submit btn only when startDate & endDate is selected
    if (allowUndefinedDateRange && from && to) return false;
    if (allowUndefinedDateRange && !from && !to) return false;

    return !from || !to;
  }, [allowUndefinedDateRange, from, to]);
  // #endregion

  // #region HANDLERS
  const onClickAll = () => {
    setFrom(undefined);
    setTo(undefined);
  };
  const onClickDefinedRange = (_startRange: Date, _endRange: Date) => {
    setMonthAndYear(startOfDay(_startRange));
    setFrom(startOfDay(_startRange));
    setTo(endOfDay(_endRange));
  };
  const onClickCustom = () => {
    // set to initial values
    if (defaultDateRange) setMonthAndYear(defaultDateRange.startDate);
    setFrom(defaultDateRange?.startDate);
    setTo(defaultDateRange?.endDate);
  };

  const checkDefinedRangeIsSelected = (
    _startRange: Date,
    _endRange: Date,
  ): boolean => {
    if (from && to)
      return isSameDay(from, _startRange) && isSameDay(to, _endRange);

    return false;
  };
  const checkCustomDefinedRangeIsSelected = (): boolean => {
    let isSelected = false;

    if (from && to && definedRangeOptions) {
      const listOfDefinedRangeIsSelected = definedRangeOptions.map(
        ({ startRange, endRange }) =>
          checkDefinedRangeIsSelected(startRange, endRange),
      );

      // inactive when there even 1 option is "true"
      isSelected = !listOfDefinedRangeIsSelected.some(Boolean);
    }

    return isSelected;
  };

  const handleClickStartDate = useCallback(
    (_startDate: Date) => {
      const monthIdx = getMonth(_startDate);
      const newMonth = setMonthFns(monthAndYear, monthIdx);
      setMonthAndYear(newMonth);
      setFrom(_startDate);
      setTo(undefined);
      setCurrentSelection('to');
    },
    [monthAndYear],
  );
  const handleClickEndDate = useCallback(
    (_endDate: Date) => {
      const monthIdx = getMonth(_endDate);
      const newMonth = setMonthFns(monthAndYear, monthIdx);
      setMonthAndYear(newMonth);
      if (from && isBefore(_endDate, from)) {
        setFrom(startOfDay(_endDate));
        setCurrentSelection('to');
      } else {
        setTo(_endDate);
        setCurrentSelection('from');
      }
    },
    [from, monthAndYear],
  );

  const onClickCancelSubmit = () => {
    // set to initial values
    setFrom(defaultDateRange?.startDate);
    setTo(defaultDateRange?.endDate);

    onClickCancel();
  };
  const onClickSubmit = () => {
    if (from && to) {
      // check if "from" is above "to" which is invalid
      if (isAfter(from, to)) {
        dispatch(
          snackbarAction.show({
            type: SnackbarTheme.warning,
            message: translator.translate(
              'Please make sure that the start date is below the end date to display the correct data',
            ),
          }),
        );
        return;
      }

      // if there is "maxDateRangeInDays" -> check if "dateRangeDifferenceInDays" is within "maxDateRangeInDays"
      if (
        maxDateRangeInDays &&
        dateRangeDifferenceInDays &&
        maxDateRangeInDays <= dateRangeDifferenceInDays
      ) {
        dispatch(
          snackbarAction.show({
            type: SnackbarTheme.warning,
            message: `${translator.translate(
              'Please make sure that the date range is within',
            )} ${maxDateRangeInDays} ${translator.translate(
              'days to display the correct data',
            )}`,
          }),
        );
        return;
      }
    }

    onClickApply(from, to);
  };
  // #endregion

  return (
    <RootContainer
      onClick={(e) => {
        e.stopPropagation();
      }}
      data-testid="DateRangePicker:RootContainer"
      disableDefinedRange={disableDefinedRange}
      isMobile={isMobile}
    >
      <HeaderContainer
        data-testid="DateRangePicker:RootContainer:HeaderContainer"
        withNotes={!!notes}
        isMobile={isMobile}
      >
        {!isMobile && (
          <HeaderText>
            {!!from && format(from, 'd MMM yyyy')}
            {!!from && !!to && ' - '}
            {!!to && format(to, 'd MMM yyyy')}
          </HeaderText>
        )}
        {!isMobile && notes && (
          <div tw="w-96">
            <Text.Paragraph tw="text-white text-xs  word-wrap[normal] whitespace-pre-wrap">
              &#9432; {notes}
            </Text.Paragraph>
          </div>
        )}

        {isMobile && (
          <div tw="flex space-x-2">
            {allowUndefinedDateRange && (
              <Chip.Small
                key="DefinedRangeOption-all"
                tw="text-grey-two"
                css={[
                  !dateRangeDifferenceInDays && tw`border-white text-orange`,
                ]}
                label={translator.translate('All')}
                selected={!dateRangeDifferenceInDays}
                onClick={onClickAll}
                testID="DefinedRangeOption-all"
              />
            )}

            {definedRangeOptions?.map((option) => (
              <Chip.Small
                key={`DefinedRangeOption-${option.label}`}
                tw="text-grey-two"
                css={[
                  checkDefinedRangeIsSelected(
                    option.startRange,
                    option.endRange,
                  ) && tw`border-white text-orange`,
                ]}
                label={translator.translate(option.label)}
                selected={checkDefinedRangeIsSelected(
                  option.startRange,
                  option.endRange,
                )}
                onClick={() =>
                  onClickDefinedRange(option.startRange, option.endRange)
                }
                testID={`DefinedRangeOption-${option.label}`}
              />
            ))}

            <Chip.Small
              tw="text-grey-two"
              css={[
                checkCustomDefinedRangeIsSelected() &&
                  tw`border-white text-orange`,
              ]}
              label={translator.translate('Custom')}
              selected={checkCustomDefinedRangeIsSelected()}
              onClick={onClickCustom}
              testID="DefinedRangeOption-Custom"
            />
          </div>
        )}
      </HeaderContainer>

      <WrapperBodyContainer
        data-testid="DateRangePicker:RootContainer:WrapperBodyContainer"
        disableDefinedRange={disableDefinedRange}
        isMobile={isMobile}
      >
        {!isMobile && !disableDefinedRange && (
          <DefinedRangeContainer css={[isMobile && tw`border-0`]}>
            {allowUndefinedDateRange && (
              <DefinedRangeButton
                isSelected={!dateRangeDifferenceInDays}
                onClick={onClickAll}
              >
                {translator.translate('All')}
              </DefinedRangeButton>
            )}

            {definedRangeOptions?.map((option) => (
              <DefinedRangeButton
                key={`DefinedRangeOption-${option.label}`}
                isSelected={checkDefinedRangeIsSelected(
                  option.startRange,
                  option.endRange,
                )}
                onClick={() =>
                  onClickDefinedRange(option.startRange, option.endRange)
                }
              >
                {translator.translate(option.label)}
              </DefinedRangeButton>
            ))}

            <DefinedRangeButton
              isSelected={checkCustomDefinedRangeIsSelected()}
              onClick={onClickCustom}
            >
              {translator.translate('Custom')}
            </DefinedRangeButton>
          </DefinedRangeContainer>
        )}

        {choosePicker === 'day' && (
          <Day
            isMobile={isMobile}
            monthAndYear={monthAndYear}
            currentSelection={currentSelection}
            onClickOpenMonth={() => setChoosePicker('month')}
            onDecrementMonth={() => {
              const newMonth = subMonths(monthAndYear, 1);
              setMonthAndYear(newMonth);
            }}
            onIncrementMonth={() => {
              const newMonth = addMonths(monthAndYear, 1);
              setMonthAndYear(newMonth);
            }}
            setStartDate={handleClickStartDate}
            setEndDate={handleClickEndDate}
            disabledDays={disabledDays}
            startDate={from}
            endDate={to}
            disableDefinedRange={disableDefinedRange}
          />
        )}

        {choosePicker === 'month' && (
          <Month
            isMobile={isMobile}
            monthAndYear={monthAndYear}
            onChangeMonth={(_monthIdx) => {
              const newMonth = setMonthFns(monthAndYear, _monthIdx);

              setMonthAndYear(newMonth);
              setChoosePicker('day');
            }}
            onClickOpenYear={() => {
              setChoosePicker('year');
            }}
            disableDefinedRange={disableDefinedRange}
          />
        )}

        {choosePicker === 'year' && (
          <Year
            isMobile={isMobile}
            monthAndYear={monthAndYear}
            onChangeYear={(_yearNumber) => {
              const newYear = setYearFns(monthAndYear, _yearNumber);

              setMonthAndYear(newYear);
              setChoosePicker('day');
            }}
            rangeYear={rangeYear}
            disableDefinedRange={disableDefinedRange}
          />
        )}
      </WrapperBodyContainer>

      <WrapperFooterContainer
        data-testid="DateRangePicker:RootContainer:WrapperFooterContainer"
        css={[isMobile && tw`py-5 w-full fixed bottom-0`]}
      >
        <Button.Outlined onClick={onClickCancelSubmit}>
          {translator.translate('Cancel')}
        </Button.Outlined>
        <Button.Solid disabled={checkDisabledSubmitBtn} onClick={onClickSubmit}>
          {translator.translate('Apply')}
        </Button.Solid>
      </WrapperFooterContainer>
    </RootContainer>
  );
}
// #endregion
