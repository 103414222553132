import { Driver } from './Driver.model';

export enum RDBDriverFilterOrderByEnum {
  NAME_ASC = 'NAME_ASC', // Driver Name A-Z (default)
  NAME_DESC = 'NAME_DESC', // Driver Name Z-A
}

export type ReportDrivingBehaviorByDriver = Pick<
  Driver,
  'fullName' | 'phoneNumber'
> & {
  completedHoDelivery: number | null;
  completedJo: number | null;
  workingDays: number | null;
  vehiclesName: string[];
  distance?: number;
  harshAcceleration?: number;
  harshBraking?: number;
  harshCornering?: number;
  maxSpeed?: number;
  overspeedCount?: number;
};

export type RDBDateRange = {
  startDate?: Date;
  endDate?: Date;
};
