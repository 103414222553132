import React from 'react';
import { Navigate } from 'react-router-dom';
import 'twin.macro';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useHaulingOrderForceCompleteViewModel from './HaulingOrderForceComplete.viewModel';

export default function HaulingOrderForceCompleteView() {
  const {
    translator: { translate },
    haulingOrderForceCompleteLogic,
    haulingOrderForceCompleteTable,
  } = useHaulingOrderForceCompleteViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <div tw="pt-10">
      <BulkActionLayout
        actionLabel={translate('to start the completion process.')}
        descriptionLabel={translate(
          'Make sure the list below is the Hauling Order list that you want to force complete. You can no longer create a Hauling Job Order for a completed Hauling Order.',
        )}
        cancelLabel={translate('Cancel')}
        submitLabel={translate('Force Complete')}
        doneLabel={translate('Done')}
        table={haulingOrderForceCompleteTable.table}
        entityLength={
          haulingOrderForceCompleteLogic.haulingOrdersWithStatusDetail.length
        }
        showDoneBtn={haulingOrderForceCompleteLogic.showDoneBtn}
        isLoading={haulingOrderForceCompleteLogic.mutationsIsLoading}
        noDataPage={
          <TableNoData
            icon={<Icon.NoDataFound />}
            titleLabel={translate(
              "You don't have any selected Hauling Order that you want to force complete",
            )}
            descriptionLabel={translate(
              'Try to select some Hauling Orders from Hauling Order page',
            )}
            actionLabel={translate('Go back to Hauling Order page')}
            onClickAction={haulingOrderForceCompleteLogic.onCancelOrDone}
          />
        }
        onSubmit={haulingOrderForceCompleteLogic.onForceComplete}
        onCancel={haulingOrderForceCompleteLogic.onCancelOrDone}
        onDone={haulingOrderForceCompleteLogic.onCancelOrDone}
      />
    </div>
  );
}
