import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useShipperDeactivateViewModel from './ShipperDeactivate.viewModel';

export default function ShipperDeactivateView() {
  const {
    translator: { translate },
    table,
    shippersWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    onDeactivate,
    onCancel,
    onDone,
  } = useShipperDeactivateViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <BulkActionLayout
      actionLabel={translate('to start the deactivation process.')}
      descriptionLabel={translate(
        'Make sure the list below is the shipper list that you want to deactivate from your organization. Inactive shippers cannot be used on Shipper Orders and Job Orders.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Deactivate')}
      doneLabel={translate('Done')}
      table={table}
      entityLength={shippersWithStatusDetail.length}
      showDoneBtn={showDoneBtn}
      isLoading={mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Shippers that you want to deactivate",
          )}
          descriptionLabel={translate(
            'Try to select some Shippers from Shipper page',
          )}
          actionLabel={translate('Go back to Shipper page')}
          onClickAction={onDone}
        />
      }
      onCancel={onCancel}
      onSubmit={onDeactivate}
      onDone={onDone}
    />
  );
}
