import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import { homeRoute } from '../Home/Home.route';
import useDrivingContestRemoveViewModel from './DrivingContestRemove.viewModel';

export default function DrivingContestRemoveView() {
  const {
    translator: { translate },
    runnerEvent,
    table,
    logic,
  } = useDrivingContestRemoveViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  // route guard
  if (!runnerEvent.isDrivingContestRegistrationInProgress) {
    return <Navigate to={homeRoute.path} replace />;
  }

  return (
    <BulkActionLayout
      actionLabel={translate('to start the removal process.')}
      descriptionLabel={translate(
        'Make sure the list below is the participant list that you want to remove as Driver Contest participant. These drivers will be no longer participate in Driver Contest.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Remove')}
      doneLabel={translate('Done')}
      table={table.table}
      entityLength={logic.drivingContestsWithStatusDetail.length}
      showDoneBtn={logic.showDoneBtn}
      isLoading={logic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Drivers that you want to remove",
          )}
          descriptionLabel={translate(
            'Try to select some Drivers from Driver Contest page',
          )}
          actionLabel={translate('Go back to Driver Contest page')}
          onClickAction={logic.onDone}
        />
      }
      onCancel={logic.onCancel}
      onSubmit={logic.onRemove}
      onDone={logic.onDone}
    />
  );
}
