import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useShipperDeactivateViewModel from './DeliveryLocationDelete.viewModel';

export default function DeliveryLocationDeleteView() {
  const {
    translator: { translate },
    deliveryLocationDeleteLogic,
    deliveryLocationDeleteTable,
  } = useShipperDeactivateViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <BulkActionLayout
      actionLabel={translate('to start the deletion process.')}
      descriptionLabel={translate(
        'Make sure the list below is the location list that you want to delete from your organization. Deleted locations cannot be used on your next Shipper Orders.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Delete')}
      doneLabel={translate('Done')}
      table={deliveryLocationDeleteTable.table}
      entityLength={
        deliveryLocationDeleteLogic.locationsWithStatusDetail.length
      }
      showDoneBtn={deliveryLocationDeleteLogic.showDoneBtn}
      isLoading={deliveryLocationDeleteLogic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Delivery Locations that you want to delete",
          )}
          descriptionLabel={translate(
            'Try to select some Delivery Locations from Delivery Location page',
          )}
          actionLabel={translate('Go back to Delivery Location page')}
          onClickAction={deliveryLocationDeleteLogic.onCancelOrDone}
        />
      }
      onCancel={deliveryLocationDeleteLogic.onCancelOrDone}
      onSubmit={deliveryLocationDeleteLogic.onDelete}
      onDone={deliveryLocationDeleteLogic.onCancelOrDone}
    />
  );
}
