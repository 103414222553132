import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useDriverDeactivateViewModel from './DriverDeactivate.viewModel';

export default function DriverDeactivateView() {
  const {
    translator: { translate },
    logic: {
      driversWithStatusDetail,
      showDoneBtn,
      mutationsIsLoading,
      onDeactivate,
      onCancel,
      onDone,
    },
    table,
  } = useDriverDeactivateViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <BulkActionLayout
      actionLabel={translate('to start the deactivation process.')}
      descriptionLabel={translate(
        'Make sure the list below is the driver list that you want to deactivate from your organization. Inactive drivers cannot be used on Shipper Orders and Job Orders.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Deactivate')}
      doneLabel={translate('Done')}
      table={table}
      entityLength={driversWithStatusDetail.length}
      showDoneBtn={showDoneBtn}
      isLoading={mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Drivers that you want to deactivate",
          )}
          descriptionLabel={translate(
            'Try to select some Drivers from Driver page',
          )}
          actionLabel={translate('Go back to Driver page')}
          onClickAction={onDone}
        />
      }
      onCancel={onCancel}
      onSubmit={onDeactivate}
      onDone={onDone}
    />
  );
}
