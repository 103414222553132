import React from 'react';
import tw, { styled } from 'twin.macro';
import {
  HelpCenterDataProps,
  HelpCenterDataSubItemProps,
} from '../../../constant/HelpCenter.constant';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { Image } from '../../atom';
import { BodyFourteen, HeadingFive } from '../../atom/Text/Text.atom';
import HelpCenterDataLayout from '../../template/HelpCenterDataLayout/HelpCenterDataLayout.template';

const Container = tw.div`flex flex-col gap-2`;

const SubItemContainer = tw.div`flex flex-col gap-2 ml-11`;
const LabelContainer = tw.div`flex gap-2`;
const DescriptionContainer = tw(BodyFourteen)`whitespace-pre-wrap`;

const ImageSection = styled.div(
  ({ isImageContainerFlexCol }: { isImageContainerFlexCol?: boolean }) => [
    tw`flex gap-4 `,
    isImageContainerFlexCol && tw`flex-col`,
  ],
);
const ImageContainer = styled(Image)(
  ({
    isMobile,
    isImageContainerFlexCol,
  }: { isMobile?: boolean; isImageContainerFlexCol?: boolean }) => [
    tw`flex flex-1 max-h-[360px] border border-beige-lines rounded object-contain bg-neutral-50`,
    isMobile && tw`max-h-[171px] w-2.5`,
    isImageContainerFlexCol && tw`w-full`,
  ],
);

type Props = HelpCenterDataProps & {
  idx: number;
  testID?: string;
};

function SubItem({
  title,
  description,
  images,
  testID,
  isImageContainerFlexCol,
  subMeta,
}: HelpCenterDataSubItemProps) {
  const { isMobile } = useScreenDetection();

  return (
    <SubItemContainer>
      <LabelContainer>
        {title && (
          <ul tw="list-disc">
            <li>
              <HeadingFive>{title}</HeadingFive>
            </li>
          </ul>
        )}
      </LabelContainer>

      {description &&
        (!title ? (
          <ul tw="list-disc">
            <li>
              <DescriptionContainer>{description}</DescriptionContainer>
            </li>
          </ul>
        ) : (
          <DescriptionContainer>{description}</DescriptionContainer>
        ))}

      {!!images?.length && (
        <ImageSection isImageContainerFlexCol={isImageContainerFlexCol}>
          {images.map((item, index) => (
            <ImageContainer
              isImageContainerFlexCol={isImageContainerFlexCol}
              onClick={() => item.onClick?.(item.url)}
              isMobile={isMobile}
              css={item.style}
              key={`help-center-data-item-image-content-${testID || ''}-${
                item.url || index
              }`}
              src={item.url}
            />
          ))}
        </ImageSection>
      )}

      {subMeta?.map((item, idx) => (
        <HelpCenterDataLayout
          key={`help-center-sub-item-${title}-${item.testID || idx}`}
          idx={idx + 1}
          description={item.description}
          alert={item.alert}
          isImageContainerFlexCol={item.isImageContainerFlexCol}
          images={item.images}
        />
      ))}
    </SubItemContainer>
  );
}

export default function HelpCenterDataItem({
  idx,
  testID,
  description,
  alert,
  isImageContainerFlexCol,
  images,
  subData,
}: Props) {
  return (
    <Container key={idx}>
      <HelpCenterDataLayout
        idx={idx}
        testID={testID}
        description={description}
        alert={alert}
        isImageContainerFlexCol={isImageContainerFlexCol}
        images={images}
      />
      {subData?.map((item) => (
        <SubItem
          testID={item.testID}
          isImageContainerFlexCol={item.isImageContainerFlexCol}
          images={item.images}
          key={item.title}
          title={item.title}
          description={item.description}
          subMeta={item.subMeta}
        />
      ))}
    </Container>
  );
}
