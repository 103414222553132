import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useActivityTemplateDeleteViewModel from './ActivityTemplateDelete.viewModel';

export default function ActivityTemplateDeleteView() {
  const {
    translator: { translate },
    activityTemplateDeleteLogic,
    activityTemplateDeleteTable,
  } = useActivityTemplateDeleteViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <BulkActionLayout
      descriptionLabel={translate(
        'Make sure the list below is the Activity Template list that you want to delete from your organization. Deleted template cannot be used on your next Shipper Orders.',
      )}
      actionLabel={translate('to start the deletion process.')}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Delete')}
      doneLabel={translate('Done')}
      table={activityTemplateDeleteTable.table}
      entityLength={
        activityTemplateDeleteLogic.activityTemplatesWithStatusDetail.length
      }
      showDoneBtn={activityTemplateDeleteLogic.showDoneBtn}
      isLoading={activityTemplateDeleteLogic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Activity Templates that you want to delete",
          )}
          descriptionLabel={translate(
            'Try to select some Activity Templates from Activity Template page',
          )}
          actionLabel={translate('Go back to Activity Template page')}
          onClickAction={activityTemplateDeleteLogic.onCancelOrDone}
        />
      }
      onCancel={activityTemplateDeleteLogic.onCancelOrDone}
      onSubmit={activityTemplateDeleteLogic.onDelete}
      onDone={activityTemplateDeleteLogic.onCancelOrDone}
    />
  );
}
